import { useEffect, useState } from 'react'
import { getNftJellyContract } from 'utils/contractHelpers'
import useRefresh from './useRefresh'

const useWinner = (address: string) => {
  const [winner, setWinner] = useState<string>()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    async function fetchWinner() {
      const contract = getNftJellyContract(address)
      const res = await contract.methods.winner().call()
      if (res !== '0x0000000000000000000000000000000000000000') {
        setWinner(res)
      }
    }
    fetchWinner()
  }, [address, fastRefresh])

  return winner
}

export default useWinner
