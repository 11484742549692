import styled from 'styled-components'
import { Card } from '@pancakeswap-libs/uikit'

const StyledCard = styled(Card)<{ isStaking?: boolean; isFinished?: boolean; finishedPool?: boolean }>`
  max-width: 352px;
  margin: 0 8px 24px;
  background: ${(props) => props.theme.card.background};
  box-shadow: 0px 2px 12px -8px rgba(25, 19, 38, 0.1), 0px 1px 1px rgba(25, 19, 38, 0.05);
  border-radius: 32px;
  display: flex;
  color: ${({ isFinished, theme }) => theme.colors[isFinished ? 'textDisabled' : 'secondary']};
  box-shadow: ${({ isStaking }) =>
    isStaking
      ? '0px 0px 0px 2px #53DEE9;'
      : '0px 2px 12px -8px rgba(25, 19, 38, 0.1), 0px 1px 1px rgba(25, 19, 38, 0.05)'};
  flex-direction: column;
  align-self: baseline;
  position: relative;
  opacity: ${({ finishedPool }) => (finishedPool ? 0.5 : 1)};
  ${({ theme }) => theme.mediaQueries.sm} {
    margin: 0 12px 46px;
  }
  &::after {
    content: 'Finished';
    color: #fff;
    position: absolute;
    right: -80px;
    top: 16px;
    display: ${({ finishedPool }) => (finishedPool ? 'inline-block' : 'none')};
    transform: rotate(45deg);
    padding: 6px 80px;
    background-color: ${({ theme }) => theme.colors.textSubtle};
  }
  /* &::before {
    content: '';
    color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    display: ${({ finishedPool }) => (finishedPool ? 'block' : 'none')};
    width: 100%;
    height: 364px;
  } */
  /* cursor: ${({ finishedPool }) => finishedPool && 'not-allowed'}; */
`

export default StyledCard
