import React from 'react'
import styled from 'styled-components'

interface NFTImageCardProps {
  nftImageUrl: string
}

const NFTImageCard: React.FC<NFTImageCardProps> = ({ nftImageUrl }) => {
  return <img src={nftImageUrl} alt="" style={{ width: 304, height: 304, borderRadius: 20 }} />
}

export default NFTImageCard
