import React, { useEffect, lazy, useState } from 'react'
// import { Router, Redirect, Route, Switch } from 'react-router-dom'
import { Router, Route, Switch } from 'react-router-dom'
import { ResetCSS } from '@pancakeswap-libs/uikit'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import { useFetchPriceList, useFetchProfile, useFetchPublicData, useFetchVaultPublicData } from 'state/hooks'
import { useWeb3React } from '@web3-react/core'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
// import ToastListener from './components/ToastListener'
import PageLoader from './components/PageLoader'
// import EasterEgg from './components/EasterEgg'
import Pools from './views/Pools'
import NFT from './views/NFT'
import history from './routerHistory'
import { openTime } from './views/Landing'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Landing = lazy(() => import('./views/Landing'))
const Buyback = lazy(() => import('./views/Buyback'))
const Referrals = lazy(() => import('./views/Referrals'))
const Vaults = lazy(() => import('./views/Vaults'))
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const Kingdoms = lazy(() => import('./views/Kingdoms'))
const NotFound = lazy(() => import('./views/NotFound'))
const Ifos = lazy(() => import('./views/Ifos'))
const Partners = lazy(() => import('./views/Partners'))
/* const Lottery = lazy(() => import('./views/Lottery'))
const NotFound = lazy(() => import('./views/NotFound'))
const Collectibles = lazy(() => import('./views/Collectibles'))
const Teams = lazy(() => import('./views/Teams'))
const Team = lazy(() => import('./views/Teams/Team'))
const Profile = lazy(() => import('./views/Profile'))
const TradingCompetition = lazy(() => import('./views/TradingCompetition'))
const Predictions = lazy(() => import('./views/Predictions')) */

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true)
  const { account } = useWeb3React()

  /*
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const ref = urlParams.get('ref') ? urlParams.get('ref') : '0x0000000000000000000000000000000000000000';
  const [referral, setReferral] = useState('0x0000000000000000000000000000000000000000');
  if ((referral === '' || referral === '0x0000000000000000000000000000000000000000') && ref !== '0x0000000000000000000000000000000000000000') {
    setReferral(ref);
  }
  */

  // Monkey patch warn() because of web3 flood
  // To be removed when web3 1.3.5 is released
  useEffect(() => {
    console.warn = () => null
  }, [])

  useEffect(() => {
    const targetTime = new Date(openTime)
    const _now = Date.now()
    const leftTime = targetTime.getTime() - _now
    if (leftTime > 0) {
      setIsOpen(false)
    }
  }, [])

  useEagerConnect()
  useFetchPublicData()
  useFetchProfile()
  useFetchPriceList()

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <SuspenseWithChunkError fallback={<PageLoader />}>
        {!isOpen ? (
          <Route path="/" exact>
            <Landing />
          </Route>
        ) : (
          <Menu>
            <Switch>
              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="/farms">
                <Farms />
              </Route>
              <Route path="/pools">
                <Farms tokenMode />
              </Route>
              <Route path="/buyback">
                <Buyback />
              </Route>
              <Route path="/referrals">
                <Referrals />
              </Route>
              <Route path="/vaults22">
                <Vaults />
              </Route>
              <Route path="/royaljelly">
                <Pools />
              </Route>
              <Route path="/nftjelly">
                <NFT />
              </Route>
              <Route path="/iho">
                <Ifos />
              </Route>
              <Route path="/partners">
                <Partners />
              </Route>

              {/*
              <Route path="/kingdoms">
                <Kingdoms />
              </Route>
              <Route path="/lottery">
                <Lottery />
              </Route>
              <Route path="/collectibles">
                <Collectibles />
              </Route>
              <Route exact path="/teams">
                <Teams />
              </Route>
              <Route path="/teams/:id">
                <Team />
              </Route>
              <Route path="/profile">
                <Profile />
              </Route>
              <Route path="/competition">
                <TradingCompetition />
              </Route>
              <Route path="/prediction">
                <Predictions />
              </Route> */}
              {/* Redirect */}
              {/* <Route path="/staking">
                <Redirect to="/pools" />
              </Route>
              <Route path="/syrup">
                <Redirect to="/pools" />
              </Route>
              <Route path="/nft">
                <Redirect to="/collectibles" />
              </Route> */}
              {/* 404 */}
              <Route component={NotFound} />
            </Switch>
          </Menu>
        )}
        {/* <EasterEgg iterations={2} />
      <ToastListener /> */}
      </SuspenseWithChunkError>
    </Router>
  )
}

export default React.memo(App)
