import React, { useMemo } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { Heading, Flex, Card, LinkExternal } from '@pancakeswap-libs/uikit'
import styled from 'styled-components'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition'
import useI18n from 'hooks/useI18n'
import usePersistState from 'hooks/usePersistState'
import { usePools, useBlock } from 'state/hooks'
import { useERC721 } from 'hooks/useContract'
import FlexLayout from 'components/layout/Flex'
import Page from 'components/layout/Page'
import PageHeader from 'components/PageHeader'
import PoolCard from 'views/NFT/components/PoolCard'
import PoolTabButtons from 'views/Pools/components/PoolTabButtons'

const PoolPage = styled(Page)`
  background-image: url('/images/honeymoon/farms_bottom.png');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0px;
  max-width: 100vw;
  padding: 0px 0px 200px 0px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0px 0px 500px 0px;
  }
`

const BottomCard = styled(Card)`
  max-width: 352px;
  align-self: center;
`

const Pools: React.FC = () => {
  const { path } = useRouteMatch()
  const TranslateString = useI18n()
  const { account } = useWeb3React()
  const pools = usePools(account).filter((p) => p.nftImageUrl)
  const { currentBlock } = useBlock()
  const [stakedOnly, setStakedOnly] = usePersistState(false, 'pancake_pool_staked_nft')
  const [finishedPools, openPools] = useMemo(
    () => partition(pools, (pool) => pool.isFinished || currentBlock > Number(pool.endBlock) + (24 * 60 * 60) / 3),
    [currentBlock, pools],
  )
  const stakedOnlyPools = useMemo(
    () => openPools.filter((pool) => pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)),
    [openPools],
  )
  const hasStakeInFinishedPools = useMemo(
    () => finishedPools.some((pool) => pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)),
    [finishedPools],
  )

  return (
    <>
      <PageHeader>
        <Flex justifyContent="space-between" flexDirection={['column', null, 'row']}>
          <Flex flexDirection="column" mr={['8px', 0]}>
            <Heading as="h1" size="xxl" color="primary" mb="24px">
              {TranslateString(999, 'NFT Jelly')}
            </Heading>
            <Heading size="md" color="primary">
              {TranslateString(999, 'Stake MOON, BEAR, HONEY to earn NFTs!')}
            </Heading>
            <Heading size="md" color="primary" pb="100px">
              {TranslateString(999, 'You can unstake at any time.')}
            </Heading>
          </Flex>
          {/*
          <Flex height="fit-content" justifyContent="center" alignItems="center" mt={['24px', null, '0']}>
            <BountyCard />
          </Flex>
          */}
        </Flex>
      </PageHeader>
      <PoolPage>
        <PoolTabButtons
          stakedOnly={stakedOnly}
          setStakedOnly={setStakedOnly}
          hasStakeInFinishedPools={hasStakeInFinishedPools}
          isNft
        />
        <FlexLayout>
          {/*
              <CakeVaultCard pool={cakePoolData} account={account} />
            */}
          {stakedOnly
            ? orderBy(stakedOnlyPools, ['sortOrder']).map((pool) => (
                <PoolCard key={pool.sousId} pool={pool} account={account} path={path} />
              ))
            : orderBy(openPools, ['sortOrder']).map((pool) => (
                <PoolCard key={pool.sousId} pool={pool} account={account} path={path} />
              ))}
          {orderBy(finishedPools, ['sortOrder']).map((pool) => (
            <PoolCard key={pool.sousId} pool={pool} account={account} path={path} finishedPool />
          ))}
          <BottomCard>
            <LinkExternal href="https://forms.gle/NQUxP9ppGngGFbnz6">
              <img alt="" src="/images/banner/apply_for_royal_jelly.png" width="450px" height="450px" />
            </LinkExternal>
          </BottomCard>
        </FlexLayout>
      </PoolPage>
    </>
  )
}

export default Pools
