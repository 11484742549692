const tokens = {
  bnb: {
    symbol: 'BNB',
    projectLink: 'https://www.binance.com/',
  },
  moon: {
    symbol: 'MOON',
    address: {
      56: '0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://honeyfarm.finance/',
  },
  bear: {
    symbol: 'BEAR',
    address: {
      56: '0xc3EAE9b061Aa0e1B9BD3436080Dc57D2d63FEdc1',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://bear.honeyfarm.finance/',
  },
  honey: {
    symbol: 'HONEY',
    address: {
      56: '0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://honey.honeyfarm.finance/',
  },
  cake: {
    symbol: 'CAKE',
    address: {
      56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
      97: '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  wbnb: {
    symbol: 'BNB',
    address: {
      56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  busd: {
    symbol: 'BUSD',
    address: {
      56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.paxos.com/busd/',
  },
  tusd: {
    symbol: 'TUSD',
    address: {
      56: '0x14016E85a25aeb13065688cAFB43044C2ef86784',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  eth: {
    symbol: 'ETH',
    address: {
      56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://ethereum.org/en/',
  },
  usdc: {
    symbol: 'USDC',
    address: {
      56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.centre.io/usdc',
  },
  dai: {
    symbol: 'DAI',
    address: {
      56: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
      97: '',
    },
    decimals: 18,
    projectLink: 'http://www.makerdao.com/',
  },
  dot: {
    symbol: 'DOT',
    address: {
      56: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://polkadot.network/',
  },
  usdt: {
    symbol: 'USDT',
    address: {
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      56: '0x55d398326f99059ff775485246999027b3197955',
    },
    decimals: 18,
    projectLink: 'https://tether.to/',
  },
  btcb: {
    symbol: 'BTCB',
    address: {
      56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://bitcoin.org/',
  },
  belt: {
    symbol: 'BELT',
    address: {
      56: '0xe0e514c71282b6f4e823703a39374cf58dc3ea4f',
      97: '0xe0e514c71282b6f4e823703a39374cf58dc3ea4f',
    },
    decimals: 18,
    projectLink: 'https://belt.fi/',
  },
  rini: {
    symbol: 'RINI',
    address: {
      56: '0x844b1b28b0fda8075ea8eb99c46339398ded1673',
      97: '0x844b1b28b0fda8075ea8eb99c46339398ded1673',
    },
    decimals: 18,
    projectLink: '',
  },
  cherry: {
    symbol: 'CHERRY',
    address: {
      56: '0x487770734490ac571cda3bc06067048ecc5caa4e',
      97: '0x844b1b28b0fda8075ea8eb99c46339398ded1673',
    },
    decimals: 18,
    projectLink: '',
  },
  mbox: {
    symbol: 'MBOX',
    address: {
      56: '0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377',
      97: '0x844b1b28b0fda8075ea8eb99c46339398ded1673',
    },
    decimals: 18,
    projectLink: '',
  },
  alpaca: {
    symbol: 'ALPACA',
    address: {
      56: '0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
      97: '0x844b1b28b0fda8075ea8eb99c46339398ded1673',
    },
    decimals: 18,
    projectLink: '',
  },
  tengu: {
    symbol: 'TENGU',
    address: {
      56: '0x6f6350d5d347aa8f7e9731756b60b774a7acf95b',
      97: '0x844b1b28b0fda8075ea8eb99c46339398ded1673',
    },
    decimals: 18,
    projectLink: '',
  },
  nabox: {
    symbol: 'NABOX',
    address: {
      56: '0x755f34709e369d37c6fa52808ae84a32007d1155',
      97: '0x844b1b28b0fda8075ea8eb99c46339398ded1673',
    },
    decimals: 18,
    projectLink: 'https://nabox.gitbook.io/',
  },
  link: {
    symbol: 'LINK',
    address: {
      56: '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
      97: '0x844b1b28b0fda8075ea8eb99c46339398ded1673',
    },
    decimals: 18,
    projectLink: '',
  },
  wizard: {
    symbol: 'WIZARD',
    address: {
      56: '0x5066C68cAe3B9BdaCD6A1A37c90F2d1723559D18',
      97: '0x844b1b28b0fda8075ea8eb99c46339398ded1673',
    },
    decimals: 18,
    projectLink: '',
  },
  qbt: {
    symbol: 'QBT',
    address: {
      56: '0x17b7163cf1dbd286e262ddc68b553d899b93f526',
      97: '0x844b1b28b0fda8075ea8eb99c46339398ded1673',
    },
    decimals: 18,
    projectLink: 'https://qbt.fi/app',
  },
  fara: {
    symbol: 'FARA',
    address: {
      56: '0xf4ed363144981d3a65f42e7d0dc54ff9eef559a1',
      97: '0x844b1b28b0fda8075ea8eb99c46339398ded1673',
    },
    decimals: 18,
    projectLink: 'https://faraland.io/',
  },
  pots: {
    symbol: 'POTS',
    address: {
      56: '0x3fcca8648651e5b974dd6d3e50f61567779772a8',
      97: '0x844b1b28b0fda8075ea8eb99c46339398ded1673',
    },
    decimals: 18,
    projectLink: 'https://moonpot.com/',
  },
  chess: {
    symbol: 'CHESS',
    address: {
      56: '0x20de22029ab63cf9a7cf5feb2b737ca1ee4c82a6',
      97: '0x844b1b28b0fda8075ea8eb99c46339398ded1673',
    },
    decimals: 18,
    projectLink: 'https://tranchess.com/',
  },
  c98: {
    symbol: 'C98',
    address: {
      56: '0xaec945e04baf28b135fa7c640f624f8d90f1c3a6',
      97: '0x844b1b28b0fda8075ea8eb99c46339398ded1673',
    },
    decimals: 18,
    projectLink: 'https://www.coin98.com/',
  },
  zoon: {
    symbol: 'ZOON',
    address: {
      56: '0x9d173e6c594f479b4d47001f8e6a95a7adda42bc',
      97: '0x844b1b28b0fda8075ea8eb99c46339398ded1673',
    },
    decimals: 18,
    projectLink: 'https://cryptozoon.io/',
  },
  yag: {
    symbol: 'YAG',
    address: {
      56: '0x2722c9db0Fc6818DC9DD3A01254Afc3804438b64',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  eros: {
    symbol: 'EROS',
    address: {
      56: '0xe0ca4724bc8b97b259d25596ee3cfab816629bad',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  sing: {
    symbol: 'SING',
    address: {
      56: '0x23894C0ce2d79B79Ea33A4D02e67ae843Ef6e563',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  ada: {
    symbol: 'ADA',
    address: {
      56: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  ftm: {
    symbol: 'FTM',
    address: {
      56: '0xAD29AbB318791D579433D831ed122aFeAf29dcfe',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  pear: {
    symbol: 'PEAR',
    address: {
      56: '0xdf7C18ED59EA738070E665Ac3F5c258dcc2FBad8',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  baby: {
    symbol: 'BABY',
    address: {
      56: '0x53E562b9B7E5E94b81f10e96Ee70Ad06df3D2657',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  bob: {
    symbol: 'BOB',
    address: {
      56: '0xE798e8f65E4252A55FFc552C17C576Ba9B05C15b',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },

  // LP
  bnb_busd: {
    symbol: 'BNB-BUSD',
    isLP: true,
    address: {
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  bear_bnb: {
    symbol: 'BEAR-BNB',
    isLP: true,
    address: {
      56: '0xC5E96F03414DA6E7eA6D2F051D23964C0B80739F',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  moon_bnb: {
    symbol: 'MOON-BNB',
    isLP: true,
    address: {
      56: '0x643D3f1C1fA1A7D5D6BDE50Bd3FDD1Cdd8A85692',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  moon_busd: {
    symbol: 'MOON-BUSD',
    isLP: true,
    address: {
      56: '0x1536d05bebb05173a19c44aa1ec0a009544aafc4',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },

  cub: {
    symbol: 'CUB',
    address: {
      56: '0x50d809c74e0b8e49e7b4c65bb3109abe3ff4c1c1',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://cubdefi.com/',
  },
  bleo: {
    symbol: 'bLEO',
    address: {
      56: '0x6421531af54c7b14ea805719035ebf1e3661c44a',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://cubdefi.com/',
  },
  dec: {
    symbol: 'DEC',
    address: {
      56: '0xe9d7023f2132d55cbd4ee1f78273cb7a3e74f10a',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://cubdefi.com/',
  },
  bake: {
    symbol: 'BAKE',
    address: {
      56: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      97: 'Z',
    },
    decimals: 18,
    projectLink: 'https://www.bakeryswap.org/',
  },
  rbs: {
    symbol: 'RBS',
    address: {
      56: '0xAfAEEe58a58867c73245397C0F768FF041D32d70',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.robiniaswap.io/',
  },

  honey_nfh_1: {
    symbol: 'HONEY_NFH_#1',
    address: {
      56: '0x0Bc9264013F95f740a31e4D160E5DCfceFA6CbD6',
      97: '',
    },
    decimals: 18,
    title: 'MAYC #1553',
    titleLink: 'https://opensea.io/assets/0x60e4d786628fea6478f785a6d7e704777c86a7c6/1553',
    projectLink: 'https://boredapeyachtclub.com/#/',
  },
  honey_nfh_2: {
    symbol: 'HONEY_NFH_#2',
    address: {
      56: '0x2BBA89Ba934e4ff2c4101003E8472546BE929286',
      97: '',
    },
    decimals: 18,
    title: 'Pudgy Penguin #8294',
    titleLink: 'https://opensea.io/assets/0xbd3531da5cf5857e7cfaa92426877b022e612cf8/8294',
    projectLink: 'https://www.pudgypenguins.io/',
  },
  honey_nfh_3: {
    symbol: 'HONEY_NFH_#3',
    address: {
      56: '0x2BBA89Ba934e4ff2c4101003E8472546BE929286',
      97: '',
    },
    decimals: 18,
    title: '0N1 #5058',
    titleLink: 'https://opensea.io/assets/0x3bf2922f4520a8ba0c2efc3d2a1539678dad5e9d/5058',
    projectLink: 'https://www.0n1force.com/',
  },
  honey_nfh_4: {
    symbol: 'HONEY_NFH_#4',
    address: {
      56: '0x2BBA89Ba934e4ff2c4101003E8472546BE929286',
      97: '',
    },
    decimals: 18,
    title: 'BAKC #9049',
    titleLink: 'https://opensea.io/assets/0xba30e5f9bb24caa003e9f2f0497ad287fdf95623/9049',
    projectLink: 'https://boredapeyachtclub.com/#/kennel-club',
  },
}

export default tokens
