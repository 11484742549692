// import tokens from './tokens'
// import farms from './farms'
// import { Ifo, Token } from './types'
import { Ifo } from './types'

/* const cakeBnbLpToken: Token = {
  symbol: farms[1].lpSymbol,
  address: farms[1].lpAddresses,
  decimals: 18,
} */

const ifos: Ifo[] = [
  {
    id: 'babyobaby1',
    address: '0xe3b614dB1fE283300480a0bd5131B0A1B294741b',
    isActive: true,
    name: 'Baby-O-Baby',
    subTitle: `Commit WBNB`,
//    description: `Baby-O-Baby is a decentralized community token that works on a Hybrid reward Dividend mechanism`,
    description: 'Vesting schedule',
    descriptionLink: 'https://medium.com/@honeyfarmchef/baby-o-baby-iho-vesting-schedule-f9087b7a247c',
    saleAmount: '1,419,354,839 BOB',
    raiseAmount: '$55,000',
//    cakeToBurn: '',
//    currencyToBurn: 'HONEY',
    projectSiteUrl: 'https://babyobaby.club/',
    currency: 'WBNB',
    currencyAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    currencyTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    tokenDecimals: 18,
    launchBlockNumber: 11708800,
    releaseBlockNumber: 11711200,
  },
  {
    id: 'babyobaby2',
    address: '0xECdF43705fEA213db830B551FcF489B5c2dC08C2',
    isActive: true,
    name: 'Baby-O-Baby',
    subTitle: `Commit HONEY`,
//    description: `Baby-O-Baby is a decentralized community token that works on a Hybrid reward Dividend mechanism`,
    description: 'Vesting schedule',
    descriptionLink: 'https://medium.com/@honeyfarmchef/baby-o-baby-iho-vesting-schedule-f9087b7a247c',
    saleAmount: '129,032,258 BOB',
    raiseAmount: '$5,000',
//    cakeToBurn: '',
//    currencyToBurn: 'HONEY',
    projectSiteUrl: 'https://babyobaby.club/',
    currency: 'HONEY',
    currencyAddress: '0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
    currencyTokenAddress: '0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
    tokenDecimals: 18,
    launchBlockNumber: 11708800,
    releaseBlockNumber: 11711200,
  },
  {
    id: 'babyobaby3',
    address: '0x71ACEb8433C115C99e8A3823912531E858351fC9',
    isActive: true,
    name: 'Baby-O-Baby',
    subTitle: `Commit BEAR`,
//    description: `Baby-O-Baby is a decentralized community token that works on a Hybrid reward Dividend mechanism`,
    description: 'Vesting schedule',
    descriptionLink: 'https://medium.com/@honeyfarmchef/baby-o-baby-iho-vesting-schedule-f9087b7a247c',
    saleAmount: '129,032,258 BOB',
    raiseAmount: '$5,000',
//    cakeToBurn: '',
//    currencyToBurn: 'HONEY',
    projectSiteUrl: 'https://babyobaby.club/',
    currency: 'BEAR',
    currencyAddress: '0xc3EAE9b061Aa0e1B9BD3436080Dc57D2d63FEdc1',
    currencyTokenAddress: '0xc3EAE9b061Aa0e1B9BD3436080Dc57D2d63FEdc1',
    tokenDecimals: 18,
    launchBlockNumber: 11708800,
    releaseBlockNumber: 11711200,
  },
  {
    id: 'babyobaby4',
    address: '0x7Ae0B3d4Fdc55774E0f6e63D3c67Add873057332',
    isActive: true,
    name: 'Baby-O-Baby',
    subTitle: `Commit MOON`,
//    description: `Baby-O-Baby is a decentralized community token that works on a Hybrid reward Dividend mechanism`,
    description: 'Vesting schedule',
    descriptionLink: 'https://medium.com/@honeyfarmchef/baby-o-baby-iho-vesting-schedule-f9087b7a247c',
    saleAmount: '129,032,258 BOB',
    raiseAmount: '$5,000',
//    cakeToBurn: '',
//    currencyToBurn: 'HONEY',
    projectSiteUrl: 'https://babyobaby.club/',
    currency: 'MOON',
    currencyAddress: '0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
    currencyTokenAddress: '0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
    tokenDecimals: 18,
    launchBlockNumber: 11708800,
    releaseBlockNumber: 11711200,
  },
  
    {
      id: 'robinia',
      address: '0x245e4f861603e4Ad40D7F50752C233BD424C07F0',
      isActive: false,
      name: 'RobiniaSwap',
      subTitle: `ROBINIA Swap IHO`,
      description: `RobiniaSwap is the Yield Farm DeFi and AMM decentralised exchange running on Binance Smart Chain`,
      saleAmount: '3,000 RBS',
      raiseAmount: '$30,000',
      cakeToBurn: '$15,000',
      currencyToBurn: 'MOON',
      projectSiteUrl: 'https://robiniaswap.com/',
      currency: 'MOON-BNB LP',
      currencyAddress: '0x643D3f1C1fA1A7D5D6BDE50Bd3FDD1Cdd8A85692',
      currencyTokenAddress: '0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
      tokenDecimals: 18,
      launchBlockNumber: 11335740,
      releaseBlockNumber: 11338140,
    },
        
  {
    id: 'honeymoon1',
    address: '0x52748dD2C85BBDe0Dd165A3072dB5612010ef5F5',
    isActive: false,
    name: 'MOON',
    subTitle: 'HoneyMoon Pre-IHO: HONEY-BNB LP',
    description: `HoneyFarm Layer3 Pre-IHO. \
  Commit HONEY-BNB LP, Get MOON`,
    saleAmount: '1,804 MOON',
    raiseAmount: '$541,200',
    cakeToBurn: '$270,600',
    currencyToBurn: 'HONEY',
    projectSiteUrl: 'https://honeyfarm.finance/',
    currency: 'HONEY-BNB LP',
    currencyAddress: '0x27418bf2e38e613560d2281db6590f3f6d446259',
    currencyTokenAddress: '0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
    tokenDecimals: 18,
    launchBlockNumber: 10593600,
    releaseBlockNumber: 10596000,
  },
  {
    id: 'honeymoon2',
    address: '0xA8Bb9a707b6a8E45129A93792fECd6E0E1458A5b',
    isActive: false,
    name: 'MOON',
    subTitle: 'HoneyMoon Pre-IHO: BEAR-BNB LP',
    description: 'HoneyFarm Layer3 Pre-IHO. Commit BEAR-BNB LP, Get MOON',
    saleAmount: '3,196 MOON',
    raiseAmount: '$958,800',
    cakeToBurn: '$479,400',
    currencyToBurn: 'BEAR',
    projectSiteUrl: 'https://honeyfarm.finance',
    currency: 'BEAR-BNB LP',
    currencyAddress: '0xC5E96F03414DA6E7eA6D2F051D23964C0B80739F',
    currencyTokenAddress: '0xc3EAE9b061Aa0e1B9BD3436080Dc57D2d63FEdc1',
    tokenDecimals: 18,
    launchBlockNumber: 10593600,
    releaseBlockNumber: 10596000,
  },

  //   {
  //     id: 'gotem',
  //     address: '0x4FfEFe9696a577b99ad713caA338666dB2410fe7',
  //     isActive: false,
  //     name: 'GOTEM',
  //     subTitle: `GOTEM is a crowdsourcing DeFi platform enabling private investigations, private security, and humanitarianism
  //     `,
  //     description: `In light of the political climate and the direction the world is taking, it is imperative that there is an outlet for transparency and global welfare. This requires a decentralized platform that cannot be shut down by any authority or special interest group. gotEMs platform brings this demand to  reality. With its decentralized crowdsourcing DeFi platform enabling crowdsourcing and crowdfunding for private investigations, private security, and humanitarian missions. We are on the cusp of a global shift in how people perceive government, safety, money, resources, and the direction of our species. We anticipate that our movement will change the way people interact and go about their personal lives. Hopefully, it will change the world in a meaningful way that will impact future generations for hundreds of years.
  //  Gotem will become the world’s medium for mediation--a platform which would provide recourse, justice and transparency. Not only subject to use by ordinary people, but one which governments can also levy resources from. The final stage being that NGOs depend on the platform to crowdsource (funds and human resources) for their humanitarian missions.`,
  //     launchDate: 'June. 30th',
  //     launchTime: '14:00 UTC',
  //     saleAmount: '2,000,000 GTX',
  //     raiseAmount: '$100,000',
  //     cakeToBurn: '$50,000',
  //     projectSiteUrl: 'https://gotem.io/',
  //     currency: 'LAZY-BUSD LP',
  //     currencyAddress: '0x159804ce45b5303f5BFE894d8A51BfB41Ab296b5',
  //     tokenDecimals: 18,
  //     releaseBlockNumber: 8749650,
  //   },
  //    {
  //     id: 'daw',
  //     address: '0x5Beb8131d746b5F56dD8b371C2ec05eeD13278d8',
  //     isActive: false,
  //     name: 'DESWAP',
  //     subTitle: `Deswap is Worlds First Synthetic Stablecoin Lending Protocol build on Polygon Chain`,
  //     description: `Deswap powers the world's first decentralised stablecoin(YAI) built on polygon network, DAW, which is backed by a basket of stablecoins and crypto assets with no centralised control .We are aiming to change the interaction between the lenders and borrowers so that people can exchange assets without seamlessly loosing their funds in the form of higher gas fee. On the Polygon chain, the Deswap Protocol is designed to enable a comprehensive algorithmic money market protocol.`,
  //     launchDate: 'June. 21st',
  //     launchTime: '13:00 UTC',
  //     saleAmount: '40,000 DAW',
  //     raiseAmount: '$100,000',
  //     cakeToBurn: '$50,000',
  //     projectSiteUrl: 'https://deswap.co/',
  //     currency: 'LAZY-BUSD LP',
  //     currencyAddress: '0x159804ce45b5303f5BFE894d8A51BfB41Ab296b5',
  //     tokenDecimals: 18,
  //     releaseBlockNumber: 8490450,
  //    },
  //   {
  //     id: 'szoo',
  //     address: '0xcaD3d9891D1a6B44930687ADA3c47395dF074065',
  //     isActive: false,
  //     name: 'SpaceZOO',
  //     subTitle: `SZOO is a progressive deflationary
  //     DeFi token built to succeed on
  //     Binance Smart Chain
  //     `,
  //     description: `SZOO(SpaceZoo Token) is a deflationary token on the Binance Smart Chain (BSC) with a circulating supply of 1,000,000,000,000,000 at launch. BSC chain was launched with an initial 50% of tokens burned and 50% of tokens in circulating supply.
  //     On each transaction, a tax of 2% will be distributed to the holders,  2% of transaction fees will be locked in liquidity pool and a further 2% will be burnt, hence incentivizing holders to hodl and decreasing the supply overtime.
  //     As the supply decreases, the scarcity of the token increases.This inversely-proportional relationship constitutes a supply and demand model.
  //     Some of the transaction taxes is donated monthly to the World Wide Fund for Nature (World Wildlife Fund), which is an official partnership with SpaceZoo.
  //     `,
  //     launchDate: 'June. 16',
  //     launchTime: '13:00 UTC',
  //     saleAmount: '12,500,000,000,000 pSZOO',
  //     raiseAmount: '$100,000',
  //     cakeToBurn: '$50,000',
  //     projectSiteUrl: 'https://spacezoo.net/',
  //     currency: 'LAZY-BUSD LP',
  //     currencyAddress: '0x159804ce45b5303f5BFE894d8A51BfB41Ab296b5',
  //     tokenDecimals: 9,
  //     releaseBlockNumber: 8347400,
  //   },
  /* {
    id: 'horizon',
    address: '0x6137B571f7F1E44839ae10310a08be86D1A4D03B',
    isActive: true,
    name: 'Horizon Protocol (HZN)',
    poolBasic: {
      saleAmount: '3,000,000 HZN',
      raiseAmount: '$750,000',
      cakeToBurn: '$375,000',
      distributionRatio: 0.3,
    },
    poolUnlimited: {
      saleAmount: '7,000,000 HZN',
      raiseAmount: '$1,750,000',
      cakeToBurn: '$875,000',
      distributionRatio: 0.7,
    },
    currency: cakeBnbLpToken,
    token: tokens.hzn,
    releaseBlockNumber: 6581111,
    campaignId: '511090000',
    articleUrl: 'https://pancakeswap.medium.com/horizon-protocol-hzn-ifo-to-be-hosted-on-pancakeswap-51f79601c9d8',
    tokenOfferingPrice: 0.25,
    isV1: false,
   
   id: 'gotem',
   address: '0x4FfEFe9696a577b99ad713caA338666dB2410fe7',
   isActive: true,
   name: 'GOTEM',
   subTitle: `GOTEM is a crowdsourcing DeFi platform enabling private investigations, private security, and humanitarianism
   `,
   description: `In light of the political climate and the direction the world is taking, it is imperative that there is an outlet for transparency and global welfare. This requires a decentralized platform that cannot be shut down by any authority or special interest group. gotEMs platform brings this demand to  reality. With its decentralized crowdsourcing DeFi platform enabling crowdsourcing and crowdfunding for private investigations, private security, and humanitarian missions. We are on the cusp of a global shift in how people perceive government, safety, money, resources, and the direction of our species. We anticipate that our movement will change the way people interact and go about their personal lives. Hopefully, it will change the world in a meaningful way that will impact future generations for hundreds of years.
Gotem will become the world’s medium for mediation--a platform which would provide recourse, justice and transparency. Not only subject to use by ordinary people, but one which governments can also levy resources from. The final stage being that NGOs depend on the platform to crowdsource (funds and human resources) for their humanitarian missions.`,
   launchDate: 'June. 30th',
   launchTime: '14:00 UTC',
   saleAmount: '2,000,000 GTX',
   raiseAmount: '$100,000',
   cakeToBurn: '$50,000',
   projectSiteUrl: 'https://gotem.io/',
   currency: 'LAZY-BUSD LP',
   currencyAddress: '0x159804ce45b5303f5BFE894d8A51BfB41Ab296b5',
   tokenDecimals: 18,
   releaseBlockNumber: 8749650,
  },
  {
    id: 'belt',
    address: '0xc9FBedC033a1c479a6AD451ffE463025E92a1d38',
    isActive: false,
    name: 'Belt (BELT)',
    poolUnlimited: {
      saleAmount: '150,000 BELT',
      raiseAmount: '$3,000,000',
      cakeToBurn: '$1,500,000',
      distributionRatio: 1,
    },
    currency: cakeBnbLpToken,
    token: tokens.belt,
    releaseBlockNumber: 5493919,
    campaignId: '511080000',
    articleUrl: 'https://pancakeswap.medium.com/belt-fi-belt-ifo-to-be-hosted-on-pancakeswap-353585117e32',
    tokenOfferingPrice: null,
    isV1: true,
  },
  {
    id: 'watch',
    address: '0x55344b55C71Ad8834C397E6e08dF5195cF84fe6d',
    isActive: false,
    name: 'Yieldwatch (WATCH)',
    poolUnlimited: {
      saleAmount: '8,000,000 WATCH',
      raiseAmount: '$800,000',
      cakeToBurn: '$400,000',
      distributionRatio: 1,
    },
    currency: cakeBnbLpToken,
    token: tokens.watch,
    releaseBlockNumber: 5294924,
    campaignId: '511070000',
    articleUrl: 'https://pancakeswap.medium.com/yieldwatch-watch-ifo-to-be-hosted-on-pancakeswap-d24301f17241',
    tokenOfferingPrice: null,
    isV1: true,
  },
  {
    id: 'berry',
    address: '0x5d028cE3435B2bB9AceBfaC599EEbA1ccD63d7dd',
    isActive: false,
    name: 'Berry (BRY)',
    poolUnlimited: {
      saleAmount: '2,000,000 BRY',
      raiseAmount: '$1,000,000',
      cakeToBurn: '$500,000',
      distributionRatio: 1,
    },
    currency: cakeBnbLpToken,
    token: tokens.bry,
    releaseBlockNumber: 4750968,
    campaignId: '511060000',
    articleUrl: 'https://pancakeswap.medium.com/berry-bry-ifo-to-be-hosted-on-pancakeswap-b4f9095e9cdb',
    tokenOfferingPrice: null,
    isV1: true,
  },
  {
    id: 'soteria',
    address: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
    isActive: false,
    name: 'Soteria (wSOTE)',
    poolUnlimited: {
      saleAmount: '1,500,000 wSOTE',
      raiseAmount: '$525,000',
      cakeToBurn: '$262,500',
      distributionRatio: 1,
    },
    currency: cakeBnbLpToken,
    token: tokens.wsote,
    releaseBlockNumber: 4086064,
    campaignId: '511050000',
    articleUrl: 'https://pancakeswap.medium.com/soteria-sota-ifo-to-be-hosted-on-pancakeswap-64b727c272ae',
    tokenOfferingPrice: null,
    isV1: true,
  },
  {
    id: 'helmet',
    address: '0xa32509d760ee47Eb2Be96D338b5d69B5FBa4eFEB',
    isActive: false,
    name: 'Helmet.insure (Helmet)',
    poolUnlimited: {
      saleAmount: '10,000,000 Helmet',
      raiseAmount: '$1,000,000',
      cakeToBurn: '$500,000',
      distributionRatio: 1,
    },
    currency: cakeBnbLpToken,
    token: tokens.helmet,
    releaseBlockNumber: 3771926,
    campaignId: '511040000',
    articleUrl: 'https://pancakeswap.medium.com/1-000-000-helmet-helmet-ifo-to-be-hosted-on-pancakeswap-3379a2a89a67',
    tokenOfferingPrice: null,
    isV1: true,
  },
  {
    id: 'tenet',
    address: '0xB922aA19A2603A07C6C9ED6c236618C9bac51f06',
    isActive: false,
    name: 'Tenet (TEN)',
    poolUnlimited: {
      saleAmount: '1,000,000 TEN',
      raiseAmount: '$1,000,000',
      cakeToBurn: '$500,000',
      distributionRatio: 1,
    },
    currency: cakeBnbLpToken,
    token: tokens.ten,
    releaseBlockNumber: 3483883,
    campaignId: '511030000',
    articleUrl: 'https://pancakeswap.medium.com/tenet-ten-ifo-to-be-hosted-on-pancakeswap-b7e1eb4cb272',
    tokenOfferingPrice: null,
    isV1: true,
  },
  {
    id: 'ditto',
    address: '0x570c9eB19553526Fb35895a531928E19C7D20788',
    isActive: false,
    name: 'Ditto (DITTO)',
    poolUnlimited: {
      saleAmount: '700,000 DITTO',
      raiseAmount: '$630,000',
      cakeToBurn: '$315,000',
      distributionRatio: 1,
    },
    currency: cakeBnbLpToken,
    token: tokens.ditto,
    releaseBlockNumber: 3279767,
    campaignId: '511020000',
    articleUrl: 'https://pancakeswap.medium.com/ditto-money-ditto-ifo-to-be-hosted-on-pancakeswap-342da3059a66',
    tokenOfferingPrice: null,
    isV1: true,
  },
  {
    id: 'blink',
    address: '0x44a9Cc8463EC00937242b660BF65B10365d99baD',
    isActive: false,
    name: 'BLINk (BLK)',
    poolUnlimited: {
      saleAmount: '100,000,000 BLINK',
      raiseAmount: '$1,000,000',
      cakeToBurn: '$500,000',
      distributionRatio: 1,
    },
    currency: cakeBnbLpToken,
    token: tokens.blink,
    releaseBlockNumber: 3279767,
    campaignId: '511010000',
    articleUrl: 'https://medium.com/pancakeswap/1-000-000-ifo-blink-joins-pancakeswap-15841800bdd8',
    tokenOfferingPrice: null,
    isV1: true,
  }, */
]

export default ifos
