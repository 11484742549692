const config = [
  {
    label: 'Layered Farming',
    icon: 'LayerIcon',
    items: [
      {
        label: 'Layer 1 Honey',
        href: 'https://honey.honeyfarm.finance/',
        target: '_blank',
      },
      {
        label: 'Layer 2 Bear',
        href: 'https://bear.honeyfarm.finance/',
        target: '_blank',
      },
      {
        label: 'Layer 3 Moon',
        href: 'https://moon.honeyfarm.finance/',
      },
      {
        label: 'Layer 4 Bee',
        href: 'https://bee.honeyfarm.finance/',
      },
    ],
  },
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://moon.honeyfarm.finance/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      //      {
      //        label: '1inch (Recommended)',
      //        href: 'https://app.1inch.io/#/56/swap/BNB/0xc3EAE9b061Aa0e1B9BD3436080Dc57D2d63FEdc1',
      //        target: "_blank"
      //      },
      {
        label: 'Exchange',
        href: 'https://pancakeswap.finance/swap?outputCurrency=0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
        target: '_blank',
      },
      {
        label: 'Liquidity',
        href: 'https://pancakeswap.finance/add/BNB/0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
        target: '_blank',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/pools',
  },
  {
    label: 'Royal Jelly',
    icon: 'RoyalJellyIcon',
    href: '/royaljelly',
  },
  {
    label: 'NFT Jelly',
    icon: 'NftIcon',
    href: '/nftjelly',
  },
  {
    label: 'IHO',
    icon: 'TicketIcon',
    href: '/iho',
  },
  //  {
  //    label: 'Honey Keeper Protocol',
  //    icon: 'BuybackIcon',
  //    href: '/buyback',
  //  },
  {
    label: 'Referrals',
    icon: 'GroupsIcon',
    href: '/referrals',
  },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'PancakeSwap',
        href: 'https://pancakeswap.info/token/0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
        target: '_blank',
      },
      {
        label: 'PooCoin',
        href: 'https://poocoin.app/tokens/0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
        target: '_blank',
      },
      {
        label: 'ApeBoard',
        href: 'https://apeboard.finance/',
        target: '_blank',
      },
      {
        label: 'Pacoca',
        href: 'https://pacoca.io/',
        target: '_blank',
      },
      {
        label: 'Arken Chart',
        href: 'https://swap.arken.finance/tokens/bsc/0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
        target: '_blank',
      },
    ],
  },
  {
    label: 'Partners',
    icon: 'TeamBattleIcon',
    href: '/partners',
  },
  {
    label: 'Docs',
    icon: 'TicketIcon',
    href: 'https://docs.honeyfarm.finance/',
    target: '_blank',
  },
  //  {
  //    label: 'Audit by Paladin',
  //    icon: 'AuditIcon',
  //    href: 'https://paladinsec.co/projects/honeyfarm-layer-3/',
  //  },
]

export default config
