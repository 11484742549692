import React from 'react'
import styled from 'styled-components'
import { Box } from '@pancakeswap-libs/uikit'
import Container from '../layout/Container'

const Outer = styled(Box)<{ background?: string }>`
  // background: ${({ theme, background }) => background || theme.colors.gradients.bubblegum};
  background-image: url('/images/honeymoon/farms_top_bg.png');
  background: linear-gradient(#19116d, #11103e);
  background-position: right;
  height: 280px;
`

const Inner = styled(Container)`
  padding-top: 16px;
  padding-bottom: 32px;
  background-image: url('/images/honeymoon/farms_top_bg.png');
  background-position: right;
  background-repeat: no-repeat;
    
  ${({ theme }) => theme.mediaQueries.md} {
    background-image: url('/images/honeymoon/farms_top_bg.png'), url('/images/honeymoon/farms_top_right.png');
  }
`

const PageHeader: React.FC<{ background?: string }> = ({ background, children, ...props }) => (
  <Outer background={background} {...props}>
    <Inner>{children}</Inner>
  </Outer>
)

export default PageHeader
