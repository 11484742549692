export default {
  cake: {
    56: '0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
    666: 'This is BEAR token',
    97: '',
  },
  masterChef: {
    56: '0x671e56C68047029F236f342b18632425C75885a3',
    97: '',
  },
  referral: {
    56: '0x9b17fe2cE39b1C5BcB6BC7A60781ECdC9adb6032',
    97: '',
  },
  buyback: {
    56: '0xA81897a38EE9Ba13cFbF510FC3B4c97Ab4630d3e',
    97: '0xAEA2ecFBB59d0815B9F8e9111DFf640f7F49BC50',
  },
  kingdoms: {
    56: '0x2e72f4b196b9e5b89c29579cc135756a00e6cbbd',
    97: '',
  },
  pcsV2masterChef: {
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    97: '',
  },
  bakery: {
    56: '0x20ec291bb8459b6145317e7126532ce7ece5056f',
    97: '',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  multiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
  cake_real: {
    56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    97: '',
  },

  sousChef: {
    97: '',
    56: '',
  },
  pancakeProfile: {
    56: '',
    97: '',
  },
  pancakeRabbits: {
    56: '',
    97: '',
  },
  bunnyFactory: {
    56: '',
    97: '',
  },
  claimRefund: {
    56: '',
    97: '',
  },
  pointCenterIfo: {
    56: '',
    97: '',
  },
  bunnySpecial: {
    56: '',
    97: '',
  },
  tradingCompetition: {
    56: '',
    97: '',
  },
  easterNft: {
    56: '',
    97: '',
  },
  cakeVault: {
    56: '0xa80240Eb5d7E05d3F250cF000eEc0891d00b51CC',
    97: '',
  },
  predictions: {
    56: '',
    97: '',
  },

}
